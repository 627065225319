<script setup lang="ts">
useHead({
  title: "climatespy.com",
});
</script>
<template>
  <v-app>
    <slot />
  </v-app>
</template>
